import detailDefault from "../../detailDefault";

export default class detailacm_accounts_countryin extends detailDefault {
    update(sAction, data) {
        if (data.field === 'country') {
            let name = sAction.translateSelect('countryinterest_dom', data.value);
            sAction.dsClear();
            sAction.dsAdd('set', data.prefix + '/fields/name/value', name);
            sAction.dsAdd('set', data.prefix + '/changes/fields/name', name);
            sAction.dsProcess();
        }
    }
}
