/**
 * Kontrola na vyplnenost povinnych fieldu
 * vraci true/false podle toho, jestli byly vyplneny vsechny povinne polozky...
 * @param prefix
 * @returns {boolean}
 */

export default function requiredFieldsCheck(prefix) {
    const fields = this.dataGet(prefix + "/fields");

    return this.dataGet(prefix + "/tabs").every((tab, index) => {
        if (tab.hidden) {
            return true;
        }

        return tab.rows.every((row) => {
            return row.every((line) => {
                return (fields.get(line)?.get?.('def')?.get?.('visible') !== false && (
                    fields.get(line)?.get?.('def')?.get?.('required') !== true ||
                    !!fields.get(line)?.get?.('value')
                ));
            });
        });
    });
}

