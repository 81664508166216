export default function exportInvoices(params) {
    const list = this.dataGet(params.prefix);

    let selected = list.selected;
    if (typeof selected == "object") {
        selected = selected.toJS();
    }
    const savedSearch = '8a93a27e-2d49-c545-716f-63d917e246f4';

    const exportData = this.reloadListParams({
        list,
        prefix: params.prefix,
        savedSearch,
        columns: true
    });

    exportData["selected"] = selected;
    exportData["module"] = list.modul;
    exportData["type"] = list.type;

    const date = new Date();

    const formData = {
        filter: exportData,
        module: list.modul,
        exportType: 'invoicesXML',
        addId: true,
        action: "listviewExport",
        name: `Faktury_${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
        type: 'invoicesXML',
    }

    this.dsClear();
    this.dsAdd("set", "conf/popup/content", "fileDownload");
    this.dsAdd("set", "conf/popup/data/state", "load");
    this.dsAdd("set", "conf/popup/show", "true");
    this.dsProcess();

    this.filesXhr({
        action: "generateFileRequest",
        data: formData,
        success: ret => {
            console.log(ret);
            if (ret.status == true) {
                this.dsClear();
                this.dsAdd("set", "conf/popup/data/state", "init");
                this.dsAdd("set", "conf/popup/data/token", ret.token);
                this.dsProcess();
            }
        }
    });
}