export default function saveField(data, doUpdate = true) {
  var self = this;
  var name = data.name;

  if(data.type == "relate" || data.type == "parent"){
    name = data.fieldName
  }

  var indexof = data.way.indexOf("/fields/" + name);
  var prefix = data.way.substr(0, indexof);

  var prevValue = null;

  if (this.getViewName() === "import") {
    this.mapImportData(data);
  } else if (data.type === "file") {
    self.dsClear();
    self.dsAdd("set", data.way + "/value", data.value.name);
    if (data.value.file.deleteFile == true) {
      self.dsAdd("set", prefix + "/changes/fields/" + data.name, "")
    } else {
      self.dsAdd("set", prefix + "/changes/fields/" + data.name, data.value.file.id + "::" + data.value.name);
      self.dsAdd("set", prefix + "/fields/" + data.name + "/def/file/id", data.value.file.id);
    }
    self.dsAdd("add", prefix + "/changes/files", data.value.file)

  } else if (data.type != "relate" && data.type != "parent") {
    var actValue = this.dataGet(prefix+"/fields/" + data.name + "/value");
    prevValue = actValue;


    //________________________________ ONLY FOR duration_minutes
    var minutesValue = undefined;
    if(data.type == "hourseMinutes"){
      minutesValue = this.dataGet(prefix+"/fields/"+data.name+"/def/minutesValue");
    }
    //________________________________ END

    self.dsClear();
    self.dsAdd("set", data.way + "/value", data.value);

    if(data.cancelEdit !== false){
    self.dsAdd("set", data.way + "/actEdit", false);
    }

    if (actValue != data.value || ["date", "datetime"].includes(data.type)) {
      self.dsAdd("set", prefix + "/changes/fields/" + data.name, data.value);
      self.dsAdd("set",data.way+"/def/edit",true);
    }

    //________________________________ ONLY FOR duration_minutes
    if(minutesValue != data.valueMinutes){
      self.dsAdd("set", data.way + "/def/minutesValue", data.valueMinutes);
      self.dsAdd("set", prefix + "/changes/fields/duration_minutes", data.valueMinutes);
    }
    //________________________________ END

    self.dsAdd("set", prefix+"/canSave", true);

  } else {
    console.log(data);
    var actValue = this.dataGet(
      prefix + "/fields/" + data.name + "/def/id_value"
    );
    prevValue = actValue;
    self.dsClear();
    self.dsAdd("set", data.way + "/value", data.value.name);
    self.dsAdd("set", data.way + "/def/id_value", data.value.id);
    
    if(data.cancelEdit !== false){
    self.dsAdd("set", data.way + "/actEdit", false);
    }
    if (actValue != data.value.id) {
      self.dsAdd("set",data.way+"/def/edit",true);
      self.dsAdd("set", prefix + "/changes/fields/" + data.name, data.value.id);
    }
    if (data.type == "parent") {
      self.dsAdd("set", prefix + "/changes/fields/parent_type", data.parent_type);
      self.dsAdd("set", data.way + "/def/parent_type_value", data.parent_type);
    }
    if (data.type == "relate") {
      self.dsAdd("set", prefix + "/changes/fields/relate/" + data.fieldName , data.value.name);
    }
    self.dsAdd("set", prefix + "/canSave", true);
  }
  if (doUpdate == true) {
    self.dsProcess();
  }
  self.custom("update",{field:data.name,value:data.value, prevValue: prevValue, prefix:prefix, module: self.dataGet(prefix+"/module")});
}
