import $ from "jquery";
import detailDefault from "../../detailDefault";

export default class detailCases extends detailDefault {
    load(sAction, data) {
        this.setAccountDynEnum(sAction, data, sAction.dataGet(`${data.prefix}/fields/project_cases_1_name/def/id_value`), false);
    }

    update(sAction, data) {
        if (data.field === 'project_cases_1project_ida') {
            this.setAccountDynEnum(sAction, data, data.value.id, true);
        }
    }

    setAccountDynEnum(sAction, data, accID, saveField = true) {
        if (accID !== sAction.dataGet(`${data.prefix}/project_enum/value`)) {
            sAction.dsClear();
            sAction.dsAdd('set', `${data.prefix}/fields/project_enum/value`, accID);
            if (saveField) {
                sAction.dsAdd('set', `${data.prefix}/changes/fields/project_enum`, accID);
            }
            sAction.dsProcess();
        }
    }
}
