import detailDefault from "../../detailDefault";

export default class detailrhk_coupons extends detailDefault {
    load(sAction,data) {
        // call immediately after detailView is load
        setDefaultFilter(sAction, data.prefix, sAction.dataGet(data.prefix + '/fields/rhk_coupons_accountsaccounts_ida/value'))
    }
    update(sAction,data) {
        // call immediately after detailView field is update
        if(data.field === 'rhk_coupons_accountsaccounts_ida') {
            setDefaultFilter(sAction, data.prefix, data.value.id);
        }
    }
    newSubRecord(sAction,data){
        // call immediately before route sub record
    }
    beforeSave(sAction,data){
        // call bfore Record is save
        return true;
    }
}

function setDefaultFilter(sAction, prefix, accountID) {
    sAction.dataSet(prefix + '/fields/rhk_coupons_contacts_name/def/defaultFilter/0/parentId', accountID);
}
